//Footer Sticky
function placeFooter() {
    if ($(document.body).height()+300 < $(window).height()) {
        $("#footer").css({
            position: "fixed",
            bottom: "0px",
            left: "0px",
        });
    } else {
        $("#footer").css({
            position: "unset"
        });
    }
}
placeFooter();
