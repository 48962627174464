//App.js => default

//Appel autres fichiers
var $ = require('jquery');
window.$ = window.jQuery = $;

//Appel Boostrap
require('bootstrap');

//Autres fichiers
require('./elements/appearlazy');
require('./elements/lazysizes');
require('./elements/HeaderSticky');
require('./elements/FooterSticky');